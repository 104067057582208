.static-content {
  margin-left: auto;
  margin-right: auto;
  max-width: 1400px;
  padding: 10px 0;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: var(--tpp-bdp-text-color);
  line-height: var(--wl-line-height-xx-large);
  font-size: var(--wl-font-size-default);
}

.static-content em {
  display: block;
  margin: 15px 0;
  text-align: center;
}

.static-content a {
  font-weight: 500;
  font-size: var(--wl-font-size-medium);
  line-height: var(--wl-line-height-medium);
  color: var(--wl-text-color-twenty);
}

.static-content img {
  display: block;
  margin: 0 auto;
}
