.editorial-content.article {
  margin-left: auto;
  margin-right: auto;
  max-width: 1133px;
  padding: 4px 8px 0;
}

@media screen and (min-width: 768px) {
  .editorial-content.article {
    padding: 4px 30px 0;
  }
}

@media screen and (min-width: 970px) {
  .editorial-content.article {
    padding: 0 64px;
  }
}
