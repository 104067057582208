.error-not-found {
  max-width: var(--wl-max-content-width);
  line-height: var(--wl-line-height-xx-large);
  margin: 0 auto;
  padding: 10px;
}

.error-not-found ul {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.error-not-found a {
  color: var(--wl-text-color-link);
}
